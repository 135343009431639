import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <h6>©Viva la libertad</h6>
    </div>
  );
}

export default Footer;
